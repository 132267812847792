<template>
  <form class="entity flex" @submit.prevent="save()">
    <div class="add main-block col-lg-6 col-md-12 col-12">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">Информация Юр.лицо</h1>
        <button type="submit" class="main-block__btn default-btn">Сохранить</button>
      </div>
      <ul class="add__items">
        <li class="add__item flex">
          <p class="add__key">Организация *</p>
          <input v-model="organization" type="text" class="add__value" placeholder="Нозвание" />
        </li>
        <li class="add__item flex">
          <p class="add__key">Полное наименование *</p>
          <input v-model="full_name" type="text" class="add__value" placeholder="наименование" />
        </li>
        <li class="add__item flex">
          <p class="add__key">БИН *</p>
          <the-mask
            v-model="bin"
            type="text"
            mask="### ### ### ###"
            class="add__value add__value_align"
            placeholder="БИН"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Расчетный счет компании *</p>
          <input type="text" class="add__value add__value_align" placeholder="" />
        </li>
        <li class="add__item flex">
          <p class="add__key">ФИО основного контактного лица *</p>
          <input v-model="surname" type="text" class="add__value add__value_align" placeholder="" />
        </li>
        <li class="add__item flex">
          <p class="add__key">Контакты основного контактного лица *</p>
          <input type="text" class="add__value add__value_align" placeholder="" />
        </li>
        <li class="add__item flex">
          <p class="add__key">Договор Контерагента *</p>
          <input
            v-model="agreement"
            type="number"
            class="add__value add__value_align"
            placeholder=""
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Email * &nbsp; (один и более через запятую)</p>
          <input v-model="email" type="text" class="add__value" placeholder="Email" />
        </li>
        <li class="add__item flex">
          <p class="add__key">№ телефона * &nbsp; (один и более через запятую)</p>
          <input v-model="phone" type="text" class="add__value" placeholder="+7"/>
          <!-- <the-mask
            v-model="phone"
            type="text"
            mask="+7 (###) ### ####,  +7 (###) ### ####,  +7 (###) ### ####,  +7 (###) ### ####"
            class="add__value add__value_aligin"
            placeholder="+7"
          /> -->
        </li>
      </ul>
    </div>
    <div class="add main-block col-5">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">Фактический адрес *</h1>
      </div>
      <ul class="add__items">
        <li class="add__item flex">
          <p class="add__key">Адрес забора *</p>
          <input
            v-model="address"
            type="text"
            class="add__value add__value_align"
            placeholder="Адрес"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Город *</p>
          <b-form-select
            v-model="city_id"
            class="transport-add__value main__select"
            :options="list"
            text-field="name"
            value-field="id"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
          <!-- <input type="text" class="add__value add__value_align" placeholder="Город" required> -->
        </li>
        <li class="add__item flex">
          <p class="add__key">Улица *</p>
          <input
            v-model="street"
            type="text"
            class="add__value add__value_align"
            placeholder="Улица"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Номер офиса/дом *</p>
          <input
            v-model="apartment_number"
            type="text"
            class="add__value add__value_align"
            placeholder="№"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Номер кв</p>
          <input v-model="number" type="text" class="add__value add__value_align" placeholder="№" />
        </li>
        <li class="add__item flex">
          <p class="add__key">Этаж</p>
          <input type="text" class="add__value add__value_align" placeholder="№" />
        </li>
      </ul>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    counterpartyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      organization: "",
      full_name: "",
      bin: "",
      name: "",
      surname: "",
      agreement: null,
      phone: "",
      city_id: null,
      address: "",
      street: "",
      apartment_number: 1212121221,
      number: "",
      email: null,
      password: null,
    };
  },
  computed: {
    ...mapState(["citiesProducts"]),
    list() {
      if (this.citiesProducts) {
        return [
          {
            country_id: null,
            name: "Выберите",
            disabled: true,
          },
          ...this.citiesProducts,
        ];
      }
      return [
        {
          country_id: null,
          name: "Выберите",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    "$route.query"() {
      if (!this.$route.query.id) {
        this.surname = "";
        this.apartment_number = null;
        this.city_id = null;
      }
    },
  },
  created() {
    this.$store.dispatch("getCitiesProducts");
  },
  mounted() {
    if (this.counterpartyData && this.$route.query.id) {
      this.surname = this.counterpartyData.surname;
      this.apartment_number = this.counterpartyData.address.apartment_number;
      this.citiesProducts.forEach((e) => {
        if (e.name === this.counterpartyData.address.city) this.city_id = e.id;
      });
    }
  },
  methods: {
    save() {
      if (this.$route.query.id) {
        this.$api
          .post("/web/edit-counteragent", {
            type: "entity",
            city_id: this.city_id,
            name: this.name,
            surname: this.surname,
            organization: this.organization,
            full_name: this.full_name,
            bin: this.bin,
            agreement: this.agreement,
            phone: this.phone,
            email: this.email,
            password: this.password,
            employee_id: this.$route.query.id,
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getContragentsList");
            this.$router.push("/database/counterparties");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      } else {
        this.$api
          .post("/web/add-contragent", {
            type: "entity",
            organization: this.organization,
            full_name: this.full_name,
            bin: this.bin,
            name: this.name,
            surname: this.surname,
            agreement: this.agreement,
            phone: this.phone,
            city_id: this.city_id,
            address: this.address,
            street: this.street,
            apartment_number: this.apartment_number,
            number: this.number,
            email: this.email
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getContragentsList");
            this.$router.push("/database/counterparties");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.entity {
  align-items: flex-start;
}

.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.transport-add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
